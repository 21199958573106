import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { Link } from "gatsby"
const Blog = ({id, title, image, date, category, slug, desc}) => {
  return <Link to={`/blog/${slug}`} className="blog" key={id}>
    <article>
      {image && <Image fluid={image.childImageSharp.fluid} className="blog-img" />}
      <div className="blog-card">
        <h4>{title || 'default title'}</h4>
        <p>{desc || 'No desc provided.'}</p>
        <div className="blog-footer">
          <p>{category || ''}</p>
          <p>{date || 'BC 143'}</p>
        </div>
      </div>
    </article>
  </Link>
}

Blog.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
}

export default Blog
